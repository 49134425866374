// JavaScript Document
if(!window.console){
    window.console = {
        log:function(){}
    };
}

var _TrackId='';

(function($) {
       $.floatingtabplugin = {
              defaultsFloatingTabParam: {
                     btnContainer: "_floatingtabcontainer",
                     HotelId:'' ,
                     GroupId:'',//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                     langkey:"en",
                     location:"left",
                     btncolor:'#2196F3',
                     TriggerAction:false,
                     serverurl:"https://live.ipms247.com/",
                     IsLayout:0,//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
              }
       };
	
	$.fn.extend({
        floatingtabplugin:function(bkFloatingConfig) {
            var options = $.extend({}, $.floatingtabplugin.defaultsFloatingTabParam, bkFloatingConfig);  
            options.btnContainer = this.attr("id");
            methods._GetDataContain(options);
        }
    });
       
    var methods = {
        test:'123',
            _GetDataContain:function(opt){
                try{
                    if(opt.TriggerAction == false)
                    {
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
                        if(opt.GroupId != '')
                        {
                            var getUrl=opt.serverurl+'booking/multiproperty_service.php';
                            var param='action=GetFloatingContain&lang_code='+opt.langkey+'&GroupCode='+opt.GroupId;
                        }//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                        else
                        {
                            var getUrl=opt.serverurl+'booking/service.php';
                            var param='action=GetFloatingContain&lang_code='+opt.langkey+'&HotelId='+opt.HotelId;
                        }
                        
                        jQuery.ajax({
                            type: "POST",
                            url: getUrl,
                            data: param,
                            success: function(response) {
                                var response = JSON.parse(jQuery.trim(response));
                                
                                _TrackId=response.TrackId;
                                var res = response.content.split('<:>');//Chinmay Gandhi - 1.0.53.61 - 29th jan 2018 - LookertoBookerGoogleDrivingWidget
                                opt.IsLayout = response.IsLayout;//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                                if(jQuery.trim(res[2]) == 1)//Chinmay Gandhi - 1.0.53.61 - 29th jan 2018 - LookertoBookerGoogleDrivingWidget
                                {
                                    var content = jQuery.trim(res[0]);
                                    //Chandrakant - 28 October 2020, Purpose - Remove open class as per Hitesh sir request
                                    var MainContain =   "<section class='WebShowHighlighted'>"+
                                                            "<div class='model-main-content'>"+content+
                                                            "</div>"+
                                                            "<div class='slide-in-out toggle-sidebar'></div>"+
                                                        "</section>";
                                    
                                    jQuery("#_floatingtabcontainer").html(MainContain);
                                    if(opt.location == 'onRight')
                                    {
                                        jQuery(".WebShowHighlighted").addClass('onRight');
                                    }
                                    if(opt.btncolor != '#2196F3')
                                    {
                                        jQuery('.btn-shw').css({'background':opt.btncolor});
                                    }
                                }
                                else
                                {
                                    jQuery("#_floatingtabcontainer").html('');//Chinmay Gandhi - 1.0.53.61 - 29th jan 2018 - LookertoBookerGoogleDrivingWidget
                                }
                                
                            },
                            complete: function(){
                                methods._RedirectToBooking(opt);
                                methods._ClosePopup(opt);
                                //Chandrakant - 31 October 2020 - START
                                //Purpose : Checking widget open or not. based on open/close, execute otherwise not. Email reference Re: looker to booker
                                setTimeout(function() { if(jQuery('.WebShowHighlighted').hasClass('open')===false)jQuery('.toggle-sidebar').click(); },30000);
                                setTimeout(function() { if(jQuery('.WebShowHighlighted').hasClass('open')===true)jQuery('.toggle-sidebar').click(); },60000);
                                //Chandrakant - 31 October 2020 - END
                            }
                        });
                    }
                }
                catch(err){
                      console.log('error message _GetDataContain');
                      console.log(err);
                }
            },
            _RedirectToBooking:function(opt){
                try{
                    jQuery('.flotbookbtn').on('click', function(e){
                        jQuery("a.flotbookbtn").removeAttr("href");
                        
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
                        if(opt.IsLayout == 1)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.HotelId+'&BookingThrough=3&L2BTrackId='+_TrackId+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else if(opt.IsLayout == 2)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.GroupId+'&BookingThrough=3&L2BTrackId='+_TrackId+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+ opt.HotelId +'&BookingThrough=3&L2BTrackId=' + _TrackId,'_blank');
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                    });
                }
                catch(err){
                    console.log('error message _RedirectToBooking');
                    console.log(err);
                }
            },
            _ClosePopup:function(opt){
                jQuery('.toggle-sidebar').on('click', function(e){
                    jQuery('.WebShowHighlighted').toggleClass('open');
                });
            }
    }
})(jQuery); 
